import React, { useEffect, useState } from 'react';

import {
  Row, Col, CardBody, Card, Container, Form, Label,
} from 'reactstrap';

import { loginApi, loginTokenVerifyApi, userDetailsApi } from '../../api/user/login';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// import images
import logoDark from '../../assets/images/logo1.png';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { storeUserDetails } from '../../store/auth';
import { Button, FormHelperText, IconButton, InputAdornment, OutlinedInput, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PORTAL, SOCKET_EVENT, USER_STATUS } from '../../common/constant';
import { socket } from '../../utils/socket';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParam] = useSearchParams();
  const [magicToken, setMagicToken] = useState(searchParam.get('token'));
  console.log('searchParam', searchParam)

  document.title = ' Login | DStayZ';

  // Password show/hide
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
      contact_number: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Please Enter Your Password'),
      contact_number: Yup.string().matches(/^[0-9]{10}$/, 'Contact number must be 10 digits').required('Contact number is required')
    }),
    onSubmit: async (values) => {
      try {
        const token = await loginApi({
          portal: PORTAL.TENANT_PORTAL,
          password: values.password,
          contact_number: values.contact_number.toString(),
          isEmail: false
        })
        let userDetails
        if (token) {
          localStorage.setItem('token-tenant', token)
          userDetails = await userDetailsApi()
          dispatch(storeUserDetails({ user: userDetails }))
          if (userDetails?.user?.is_password_reset) {
            navigate("/dashboard")
          } else {
            navigate("/reset-password")
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  });

  const verifyLoginTokenHandler = async () => {
    try {
      const response = await loginTokenVerifyApi(magicToken)
      console.log('response', response)
      let payload = response?.user?.status === USER_STATUS.ACTIVE ? {
        bed: response?.user?.bed ? { ...response?.user?.bed } : { ...response?.user?.advance_bed_booking}, property_id: response?.user?.tenant?.property_id
      } : {
          bed: {
            id: parseInt(searchParam.get('bed_id')),
            room_id: parseInt(searchParam.get('room_id')),
            expiry_at: null,
            is_temporarily: false,
            user: null
        },
        property_id: parseInt(searchParam.get('property_id')),

      }
      if (response?.user) {
        socket.emit(SOCKET_EVENT.BED_BOOKING_REFRESH_SEND, { ...payload })
      }
      let userDetails
      if (response?.token && response?.user?.status === USER_STATUS.ACTIVE) {
        localStorage.setItem('token-tenant', response?.token)
        userDetails = await userDetailsApi()
        dispatch(storeUserDetails({ user: userDetails }))
        if (userDetails?.user?.is_password_reset) {
          navigate("/dashboard")
        } else {
          navigate("/reset-password")
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (magicToken) {
      verifyLoginTokenHandler();
      setMagicToken(null);
    }
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });
  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>

        <Row className="align-items-center justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card>
              <Row>
                <Col lg={12}>
                  <div className="text-center">
                    <Link to="/" className="mt-2 d-block auth-logo">
                      <img src={logoDark} alt="" height="48" className="logo logo-dark" />
                    </Link>
                  </div>
                </Col>
              </Row>

              <CardBody>
                <div className="text-center">
                  <h5 className="text-primary">Welcome to Dstayz Tenant&apos;s Portal!</h5>
                  <p className="text-muted">Sign in to continue to DStayZ.</p>
                </div>
                <div className="p-2 mt-4">
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >

                    <div className="mb-3">
                      <Label className="form-label">Phone Number</Label>
                      <TextField
                        name="contact_number"
                        className="form-control"
                        placeholder="Enter Phone Number"
                        onChange={validation.handleChange}
                        type="text"
                        onBlur={validation.handleBlur}
                        value={validation.values.contact_number || ''}
                        error={
                          !!(validation.touched.contact_number && validation.errors.contact_number)
                        }
                        size='small'
                      />
                      {validation.touched.contact_number && validation.errors.contact_number && (
                        <FormHelperText error id="accountId-error">
                          {validation.errors.contact_number}
                        </FormHelperText>
                      )}
                    </div>

                    <div className="mb-3">
                      <div className="float-end">
                        <Link to="/recover-password" className="text-muted">Forgot password?</Link>
                      </div>
                      <Label className="form-label">Password</Label>
                      <OutlinedInput
                        name="password"
                        placeholder="Enter Password"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ''}
                        error={!!(validation.touched.password && validation.errors.password)}
                        size='small'
                        endAdornment={
                          <InputAdornment position="center">
                            <IconButton
                              aria-label={
                                showPassword ? 'hide the password' : 'display the password'
                              }
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {!!(validation.touched.password && validation.errors.password) && (
                        <FormHelperText error id="accountId-error">
                          {validation.errors.password}
                        </FormHelperText>
                      )}
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customControlInline"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="mt-3 text-end">
                      <Button
                        className={validation.isValid ? "yellow_gradient_btn" : ''}
                        disabled={!validation.isValid}
                        type="submit"
                      >
                        Log In
                      </Button>
                    </div>

                    <div className="mt-4 text-center">
                      <p className="mb-0">
                        Don&apos;t have an account ?
                        <a href="/pages-register" className="fw-medium text-primary"> Signup now </a>
                      </p>
                    </div>
                  </Form>
                </div>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
