export const PER_PAGE = [5, 10, 20, 50, 100];

export const DEFAULT_PER_PAGE_VALUE = 10;

export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY'

export const DEFAULT_DATETIME_FORMAT = 'DD-MM-YYYY hh:mm A'

export const PORTAL = {
    TENANT_PORTAL: 'Tenant Portal',
    BACKOFFICE_PORTAL: 'Backoffice Portal',
    OWNER_PORTAL: 'Owner Portal',
};
export const PAYMENT_STATUS = {
    PAID: 'Paid',
    PENDING: 'Pending',
    DUE: 'Due',
    PROCESSING: 'Processing',
    DRAFT: 'Draft',
    PUBLISHED: 'Published'
}
export const BED_TYPES = {
    SINGLE: 'Single Sharing',
    DOUBLE: 'Double Sharing',
    TRIPLE: 'Triple Sharing',
}

export const VACANT_REQUEST_ACKNOWLEDGE_STATUS = {
    PENDING: 'Pending', // default
    ACKNOWLEDGED: 'Acknowledged',
    CANCELLED: 'Cancelled',
};

export const ACTIONS = {
    CREATE: 'Create',
    EDIT: 'Edit',
    VIEW: 'View',
    DELETE: 'Delete',
};

export const KNOWN_ROLES = {
    SUPER_ADMIN: 'Super Admin',
    OWNER: 'Owner',
    TENANT: 'Tenant',
    PROPERTY_MANAGER: 'Property Manager',
    PROSPECTING_OWNER: 'Prospecting Owner',
}

export const PROPERTY_TYPES = {
    APARTMENT: 'Apartment',
    PERSONAL_HOME: 'Personal Home',
};

export const ALL_MODULES = {
    TENANTS: 'Tenants',
    OWNERS: 'Owners',
    PROPERTIES: 'Properties',
    COMPLAIN_TICKETS: 'Complain Tickets',
    ADD_PROPERTY_REQUESTS: 'Add Property Requests',
    MESSAGE: 'Message',
    NOTIFICATION: 'Notification',
}


export const OPERATION_TYPE = {
    CREATE: 'Create',
    UPDATE: 'Edit',
    DELETE: 'Delete',
    READ: 'Read',
    ADD_TENANT: 'Add Tenant',
    REMOVE_TENANT: 'Remove Tenant',
}

export const PAYMENT_MODE = {
    UPI: 'upi',
    BANK: 'bank',
};

export const GENDER_PREFERENCE = {
    GIRLS_PG: `Girls' PG`,
    BOYS_PG: `Boys' PG`,
    COLIVE_PG: 'Colive PG'
}

export const PROPERTY_STATUS = {
    ACTIVE: 'Active', // DEFAULT
    LIVE: 'Live',
    SUSPENDED: 'Suspended',
    CLOSED: 'Closed',
};

export const SOCKET_EVENT = {
  PROPERTY_STATUS_SEND: 'property_status_send',
  PROPERTY_STATUS_EMIT: 'property_status_emit',
  BED_BOOKING_SEND: 'bed_booking_send',
  BED_BOOKING_EMIT: 'bed_booking_emit',
  FOOD_MENU_SEND: 'food_menu_send',
  FOOD_MENU_EMIT: 'food_menu_emit',
  ADD_TENANT_SEND: 'add_tenant_send',
  ADD_TENANT_EMIT: 'add_tenant_emit',
  PG_VACANT_SEND: 'pg_vacant_send',
  PG_VACANT_EMIT: 'pg_vacant_emit',
  PG_VACANT_CANCEL_SEND: 'pg_vacant_cancel_send',
  PG_VACANT_CANCEL_EMIT: 'pg_vacant_cancel_emit',
  CANCEL_BOOKING_SEND: 'cancel_booking_send',
  CANCEL_BOOKING_EMIT: 'cancel_booking_emit',
  BED_BOOKING_REFRESH_SEND: 'bed_booking_refresh_send',
  BED_BOOKING_REFRESH_EMIT: 'bed_booking_refresh_emit',
};

export const USER_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};


