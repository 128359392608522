import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Button } from 'reactstrap';
import GenerateLeavingRequest from '../../components/Dashboard/generateLeavingRequest';
import MaskGroup from '../../assets/images/Mask group.png';
import { Skeleton } from "@mui/material";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { getVacantRequest } from '../../api/vacant_request';
import { useProfile } from '../../components/Hooks/UserHooks';
import dayjs from 'dayjs';
import { KNOWN_ROLES, VACANT_REQUEST_ACKNOWLEDGE_STATUS } from '../../common/constant';
import { getAllGlobalSettings } from '../../api/global_settings';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import AddBankDetails from '../../components/Dashboard/addBankDetails';
import moment from 'moment';

export default function PGVacantRequest() {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const { user } = useProfile();
    const [vacantRequestData, setVacantRequestData] = useState();
    const [noticePeriods, setNoticePeriods] = useState()
    const [bedDetails, setBedDetails] = useState();
    const [isPGVacantButtonEnable, setIsPGVacantButtonEnable] = useState(false)
    const [isAddBankDetailsModal, setIsAddBankDetailsModal] = useState(false)
    document.title = ' Pg Vacant Request | DStayZ';

    useEffect(() => {
        fetchTenantDetails()
        fetchGlobalSettings()
    }, [])

    const fetchTenantDetails = async () => {
        try {
            setIsLoading(true)
            const response = (await getVacantRequest(user.id)).data.data
            if (response?.tenant) {
                setIsPGVacantButtonEnable(response?.tenant?.joining_date ? !dayjs().isBefore(dayjs(response?.tenant?.joining_date)) : false)
                setBedDetails({ tenant_id: response.tenant.id, bed_id: response.tenant?.user?.bed?.id, property: response?.tenant?.property })
            } else {
                setIsPGVacantButtonEnable(response?.vacant_request[0]?.tenant?.joining_date ? !dayjs().isBefore(dayjs(response?.vacant_request[0]?.tenant?.joining_date)) : false)
                setVacantRequestData(response.vacant_request)
                setBedDetails({ tenant_id: response.vacant_request[0].tenant_id, bed_id: response.vacant_request[0]?.bed_id, property: response?.vacant_request[0]?.tenant?.property })
            }
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    const fetchGlobalSettings = async () => {
        try {
            setIsLoading(true)
            const response = (await getAllGlobalSettings({ search: 'notice_period' })).data
            setNoticePeriods(response.data[0].payload)
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const toggleBankDetail = () => {
        setIsAddBankDetailsModal(!isAddBankDetailsModal)
    }

    const checkStatus = () => {
        const result = vacantRequestData?.filter((item) => item.acknowledge_status !== VACANT_REQUEST_ACKNOWLEDGE_STATUS.CANCELLED)
        return result?.length === 0
    }

    return (
        <div className="page-content">
            <Container fluid>
                <div className='complain_ticket'>
                    <div className='inner_page_header'>
                        PG Leaving Request
                    </div>
                    <div className='overall_tickets list-inline'>
                        {vacantRequestData ? (
                            vacantRequestData?.map((item, index) => (
                                <div className='mb-4' key={index}>
                                    <div className='single_ticket'>
                                        <div className='single_ticket_header d-flex justify-content-between align-items-center'>
                                            <div className='ticket_id d-flex align-items-center'>
                                                <CalendarTodayOutlinedIcon />
                                                <div className='mx-2'>Leaving Date </div>
                                                    <b>{dayjs(item?.leaving_date).format('DD-MM-YYYY')}</b>
                                                </div>
                                                <div className="rs_header_right d-flex">
                                                    <div
                                                        className={(item?.creator?.role?.role !== KNOWN_ROLES.TENANT && item?.tenant?.bank_detail) ? 'ticket_id me-3' : 'ticket_id'}
                                                        style={(item?.creator?.role?.role !== KNOWN_ROLES.TENANT && !item?.tenant?.bank_detail) ? {
                                                            borderRight: "1px solid #ddd",
                                                            paddingRight: "12px",
                                                            paddingLeft: '12px'
                                                        } : {
                                                            paddingLeft: '12px'
                                                        }}
                                                    >
                                                        Created by <b>{item.creator?.name} at {dayjs(item.created_at).format('DD-MM-YYYY')}</b>
                                                    </div>
                                                    {item?.creator && item?.creator?.role?.role !== KNOWN_ROLES.TENANT && (!item?.tenant?.bank_detail?.account_number && !item?.tenant?.bank_detail?.upi_id) && item.acknowledge_status !== VACANT_REQUEST_ACKNOWLEDGE_STATUS.CANCELLED && (
                                                        <div
                                                            className="yellow_gradient_btn"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                setIsAddBankDetailsModal(true)
                                                            }}
                                                        >
                                                            <PersonAddAltOutlinedIcon /> Add Bank Details
                                                        </div>
                                                    )}
                                                </div>
                                        </div>
                                        <div className='single_ticket_body'>
                                            <div className='leaving_reason'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                <div className='responsive_word_break'>Leaving Reason</div>
                                                <div><b>{item.leave_reason}</b></div>
                                                </div>
                                                <div className='align-self-start text-warning'>*To cancel the PG leaving request please contact with Admin</div>
                                                </div>
                                                    {item.acknowledge_status !== VACANT_REQUEST_ACKNOWLEDGE_STATUS.CANCELLED && item?.tenant?.bank_detail?.account_holder_name && (
                                                    <div className='col-12 mt-4'>
                                                        <div className='bankAccountDet'>
                                                            <h5 className='mb-3'>Bank Account Details</h5>
                                                        </div>
                                                        <Row>
                                                            <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-2'>
                                                                <div className='tenantDetailTitle'>Bank Name :</div>
                                                                <div className='tenantDetailDesc'>
                                                                    {item?.tenant?.bank_detail?.bank_name ?? 'N/A'}
                                                                </div>
                                                            </div>

                                                            <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-2'>
                                                                <div className='tenantDetailTitle'>IFSC :</div>
                                                                <div className='tenantDetailDesc'>
                                                                    {item?.tenant?.bank_detail?.ifsc ?? 'N/A'}
                                                                </div>
                                                            </div>
                                                        </Row>
                                                        <Row>
                                                            <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-2'>
                                                                <div className='tenantDetailTitle'>Account Name :</div>
                                                                <div className='tenantDetailDesc'>
                                                                    {item?.tenant?.bank_detail?.account_holder_name ?? 'N/A'}
                                                                </div>
                                                            </div>

                                                            <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-2'>
                                                                <div className='tenantDetailTitle'>Account Number :</div>
                                                                <div className='tenantDetailDesc'>
                                                                    {item?.tenant?.bank_detail?.account_number ?? 'N/A'}
                                                                </div>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                )}
                                                    {item?.acknowledge_status !== VACANT_REQUEST_ACKNOWLEDGE_STATUS.CANCELLED && item?.tenant?.bank_detail?.upi_id && (
                                                        <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                                                            <div className='tenantDetailTitle'>UPI ID :</div>
                                                            <div className='tenantDetailDesc'>
                                                                {item?.tenant?.bank_detail?.upi_id ?? 'N/A'}
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='single_ticket comment_refund_note'>
                                        <div className='single_ticket_body leaving_reason'>
                                            <div className='refund_note d-grid'>
                                                <div className='first_block'>
                                                    <div className='responsive_word_break'>Admin Note</div>
                                                    <div><b>{item?.note?.description ? item?.note.description : 'N/A'}</b></div>
                                                </div>
                                                <div className='first_block middle_inbetween_block px-2'>
                                                    <div className='responsive_word_break'>Refund Amount</div>
                                                    <b className='ticket_price'>{item.refund_amount}</b>
                                                </div>
                                                <div className='second_block px-2'>
                                                    <div className='responsive_word_break'>Acknowledgement Status</div>
                                                    <div className='ticket_status'>
                                                        {item?.acknowledge_status === VACANT_REQUEST_ACKNOWLEDGE_STATUS.CANCELLED ? `${item?.acknowledge_status} by ${item.note.created_by.name} at ${dayjs(item.updated_at).format('DD-MM-YYYY')}` : item?.acknowledge_status}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) :
                            (isLoading &&
                                <Col md={12}>
                                    <Row>
                                        <Col md={12}>
                                            <Skeleton variant="rounded" className="p-2 m-2 h-75" ></Skeleton>
                                        </Col>
                                        <Col md={12}>
                                            <Skeleton variant="rounded" className="p-2 m-2 h-75" ></Skeleton>
                                        </Col>
                                        <Col md={12}>
                                            <Skeleton variant="rounded" className="p-2 m-2 h-75" ></Skeleton>
                                        </Col>
                                        <Col md={12}>
                                            <Skeleton variant="rounded" className="p-2 m-2 h-75" ></Skeleton>
                                        </Col>
                                        <Col md={12}>
                                            <Skeleton variant="rounded" className="p-2 m-2 h-75" ></Skeleton>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        }
                    </div>
                    {(!vacantRequestData || (vacantRequestData?.length > 0 && checkStatus() && isPGVacantButtonEnable)) && !isLoading && (
                        <div className='pg_vacant_request'>
                            <img src={MaskGroup} alt={MaskGroup} />
                            <div className='pg_vacant_request_content mt-3 text-center'>
                                <h5>No Active Leaving Request Found!</h5>
                                {isPGVacantButtonEnable && (
                                    <><p>If you want to generate leaving request click the Generate Request button</p>
                                        <Button className="yellow_gradient_btn pay_full_amt" onClick={handleClickOpen} 
                                disabled={!bedDetails?.bed_id || moment().isBefore(user.tenant.joining_date)} 
                                style={bedDetails?.bed_id ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}> Generate PG Leaving Request </Button></>
                                )} 
                            </div>
                        </div>
                    )}
                </div>
            </Container>
            {open && (<GenerateLeavingRequest open={open} onClose={handleClose} noticePeriods={noticePeriods} bedDetails={bedDetails} refresh={fetchTenantDetails} />)}
            {isAddBankDetailsModal && (<AddBankDetails open={isAddBankDetailsModal} onClose={toggleBankDetail} tenant_id={bedDetails?.tenant_id} refresh={fetchTenantDetails} />)}
        </div>
    );
}
