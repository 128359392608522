import React from "react";
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { useProfile } from "../../Hooks/UserHooks"
import { getS3BaseUrl } from "../../../helpers/string_helper";
import FileList from "../../Common/FileList";
import { FileUpload } from '@mui/icons-material';
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../../common/constant";
export default function ViewProfileDrawer({
  isOpen,
  toggleDrawer,
  setIsEditMode,
}) {
  const { user } = useProfile()
  const s3BaseUrl = getS3BaseUrl()

  const handleClose = () => {
    setIsEditMode && setIsEditMode(false);
    toggleDrawer(false);
  };

  return (
    <Modal 
            isOpen={isOpen} 
            className="tenantDetailsDrawer tenanatProfileDetailDrawer"
        >
      <div className="profileViewer">
        <ModalHeader toggle={handleClose}>
          View Profile Details
        </ModalHeader>
        <ModalBody>
          <div className=''>
            <Row className='mb-3'>
              <div className='col-sm-12'>
                <div className='custom-image-upload'>
                  {user?.profile_photo && (
                    <div
                      className="image-placeholder"
                    >
                      <img
                        src={user?.profile_photo ? `${s3BaseUrl}${user?.profile_photo}` : FileUpload}
                        alt={user.profile_photo}
                      />
                    </div>
                  )}
                  <div className='upload_picture'>
                    <div className='pro_person_name'>
                      {user?.name}
                    </div>
                    <div className='pro_person_mob'>
                      <i className='fas fa-phone-alt me-1' />
                      <span className='country_code'>+91</span>
                      <span className='pr_person_contact'>
                        {user?.contact_number ?? 'N/A'}
                      </span>
                    </div>
                    <div className='pro_person_mob tenantDetailEmail'>
                      <span className='pr_person_contact d-flex align-items-baseline'>
                        <i className='fas fa-envelope me-1' />
                        {user?.email ?? 'N/A'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <Row className='mb-3'>
              <div className='col-sm-6 d-flex align-items-start custom_border_bottom'>
                <div className='tenantDetailTitle'>Tenant Type :</div>
                <div className='tenantDetailDesc'>
                  {user?.tenant?.type ?? 'N/A'}
                </div>
              </div>

              <div className='col-sm-6 d-flex align-items-start custom_border_bottom'>
                <div className='tenantDetailTitle'>College/ Office :</div>
                <div className='tenantDetailDesc'>
                  {user?.tenant?.organization_name ?? 'N/A'}
                </div>
              </div>
            </Row>
            <Row className='mb-3'>
              <div className='col-sm-6 d-flex align-items-start custom_border_bottom'>
                <div className='tenantDetailTitle'>
                  Emergency Contact Person:
                </div>
                <div className='tenantDetailDesc'>
                  {user?.tenant?.local_guardian_name ?? 'N/A'}
                </div>
              </div>

              <div className='col-sm-6 d-flex align-items-start custom_border_bottom'>
                <div className='tenantDetailTitle'>
                  Emergency Contact Number:
                </div>
                <div className='tenantDetailDesc'>
                  +91 {user?.tenant?.local_guardian_contact_no ?? 'N/A'}
                </div>
              </div>
            </Row>
            <Row className='mb-3'>
              <div className='col-sm-6 d-flex align-items-start custom_border_bottom'>
                <div className='tenantDetailTitle'>Agreement Status :</div>
                <div className='tenantDetailDesc'>
                  {user?.tenant?.agreement?.status ?? 'N/A'}
                </div>
              </div>

              <div className='col-sm-6 d-flex align-items-start custom_border_bottom'>
                <div className='tenantDetailTitle'>
                  Police Verification Status :
                </div>
                <div className='tenantDetailDesc'>
                  {user?.tenant?.police_verification?.status ?? 'N/A'}
                </div>
              </div>
            </Row>
            <Row className='mb-3'>
              <div className='col-sm-6 d-flex align-items-start custom_border_bottom'>
                <div className='tenantDetailTitle'>
                  Tenant’s Blood Group :
                </div>
                <div className='tenantDetailDesc'>
                  {user?.blood_group ?? 'N/A'}
                </div>
              </div>
              <div className='col-sm-6 d-flex align-items-start custom_border_bottom'>
                <div className='tenantDetailTitle'>
                  Booking Date :
                </div>
                <div className='tenantDetailDesc'>
                  {moment(user?.tenant?.joining_date).format(DEFAULT_DATE_FORMAT) ?? 'N/A'}
                </div>
              </div>
            </Row>
            <Row className='mb-3'>
              <div className='col-sm-6 d-flex align-items-start custom_border_bottom'>
                <div className='tenantDetailTitle'>
                  Caution money :
                </div>
                <div className='tenantDetailDesc'>
                  {user?.tenant?.refund_deposits[0]?.deposit_amount ?? 'N/A'}
                </div>
              </div>
              <div className='col-sm-6 d-flex align-items-start custom_border_bottom'>
                <div className='tenantDetailTitle'>
                  Transaction Id :
                </div>
                <div className='tenantDetailDesc'>
                  {user?.tenant?.refund_deposits[0]?.payment?.transaction_id ?? 'N/A'}
                </div>
              </div>
            </Row>
            <Row className='mb-3'>
              <div className='col-sm-6 d-flex align-items-start custom_border_bottom'>
                <div className='tenantDetailTitle'>
                  Promo Code :
                </div>
                <div className='tenantDetailDesc'>
                  {user?.tenant?.applied_promo_code ?? 'N/A'}
                </div>
              </div>
              <div className='col-sm-6 d-flex align-items-start custom_border_bottom'>
                <div className='tenantDetailTitle'>
                  Discount Value :
                </div>
                <div className='tenantDetailDesc'>
                  {user?.tenant?.discount_value ?? 'N/A'}
                </div>
              </div>
            </Row>
            <Row className='mb-3'>
              <div className='col-sm-6 d-flex align-items-start custom_border_bottom'>
                <div className='tenantDetailTitle'>
                  Floor :
                </div>
                <div className='tenantDetailDesc'>
                  {user?.bed?.room?.flat?.floor?.name ?? user?.bed?.room?.floor?.name ?? 'N/A'}
                </div>
              </div>
              <div className='col-sm-6 d-flex align-items-start custom_border_bottom'>
                <div className='tenantDetailTitle'>
                  Flat :
                </div>
                <div className='tenantDetailDesc'>
                  {user?.bed?.room?.flat?.name ?? 'N/A'}
                </div>
              </div>
            </Row>
            <Row className='mb-3'>
              <div className='col-sm-6 d-flex align-items-start custom_border_bottom'>
                <div className='tenantDetailTitle'>
                  Room :
                </div>
                <div className='tenantDetailDesc'>
                  {user?.bed?.room?.name ?? 'N/A'}
                </div>
              </div>
              <div className='col-sm-6 d-flex align-items-start custom_border_bottom'>
                <div className='tenantDetailTitle'>
                  Bed :
                </div>
                <div className='tenantDetailDesc'>
                  {user?.bed?.name ?? 'N/A'}
                </div>
              </div>
            </Row>
            <Row className='mb-3'>
              <div className='col-sm-12 custom_border_bottom'>
                {
                  user?.tenant?.other_documents?.length > 0 && (
                    <>
                      <div className='tenantDetailTitle tenantDetFullTitle'>
                        Attachments :
                      </div>
                      <FileList files={user?.tenant?.other_documents} />
                    </>
                  )
                }
              </div>
            </Row>
          </div>
        </ModalBody>``
      </div>
    </Modal>
  );
}