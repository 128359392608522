import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { Link, useLocation } from 'react-router-dom';

// i18n
import { withTranslation } from 'react-i18next';
import withRouter from '../Common/withRouter';
import { useProfile } from '../Hooks/UserHooks';

function SidebarContent(props) {
  // const ref = useRef();
  const { user } = useProfile();

  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      // scrollElement(item);
      return false;
    }
    // scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (let i = 0; i < items.length; ++i) {
      const item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains('active')) {
        item.classList.remove('active');
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.remove('mm-show');
        }

        parent.classList.remove('mm-active');
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove('mm-show');

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('mm-active'); // li
            parent3.childNodes[0].classList.remove('mm-active');

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove('mm-show'); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('mm-show'); // li
                parent5.childNodes[0].classList.remove('mm-active'); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  // useEffect(() => {
  //   ref.current.recalculate();
  // }, []);

  useEffect(() => {
    new MetisMenu('#side-menu');
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  // function scrollElement(item) {
  //   if (item) {
  //     const currentPosition = item.offsetTop;
  //     if (currentPosition > window.innerHeight) {
  //       ref.current.getScrollElement().scrollTop = currentPosition - 300;
  //     }
  //   }
  // }
  let accessableRoutes = [];

  // Storing defult routes
  accessableRoutes =
    [
      {
        link: '/dashboard',
        iconClass: 'uil-home-alt',
        title: 'Dashboard'
      },
      {
        link: '/complain-ticket',
        iconClass: 'mdi mdi-file-chart-outline',
        title: 'Complain Tickets'
      },
      {
        link: '/notifications',
        iconClass: 'mdi mdi-bell-ring-outline',
        title: 'Notifications'
      },
      {
        link: '/pg-vacant-request',
        iconClass: 'mdi mdi-domain',
        title: 'PG Leaving Requests'
    },
      {
        link: '/cancel-booking',
        iconClass: 'mdi mdi-domain',
        title: 'Cancel Booking'
      },
      {
        link: '/rent-payment-history',
        iconClass: 'mdi mdi-credit-card-outline',
        title: 'Payment History'
      },
      {
        link: '/message',
        iconClass: 'mdi mdi-message-processing-outline',
        title: 'Message'
      },
    ]
  // Add routes for past tenants
  if (user?.tenant?._count?.vacant_requests > 0) {
    accessableRoutes.push({
      link: '/past-stays',
      iconClass: 'mdi mdi-message-processing-outline',
      title: 'Stay History'
    },)
  }

  return (
    <SimpleBar style={{ maxHeight: '100%' }}>
      <div id='sidebar-menu'>
        <ul className='metismenu list-unstyled' id='side-menu'>
          {accessableRoutes.length ? accessableRoutes.map((element, index) =>
          (<li key={index}>
            <Link to={element?.link} className='waves-effect'>
              <i className={element?.iconClass} />
              <span>{props.t(element?.title)}</span>
            </Link>
          </li>)) : ''}
        </ul>
      </div>
    </SimpleBar>
  );
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
